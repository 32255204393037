.trackinPage{
    font-family: 'Raleway', sans-serif;
}
.container-tracking{
    padding: 80px;
}
.rastreo{
      
    border: solid 1px #DEDEDE;
    border-radius: 15px;
    padding: 15px 20px;
    gap: 10px;
}
.rastreo small{
    font-size: 14px;
    font-weight: 400;
}
.rastreo span{
    font-weight: 600;
}
.rastreo-info .accordion-button:not(.collapsed){
    background-color: transparent;
}
.rastreo-info .accordion-button:focus {
    border-color: transparent;
    outline-color: transparent;
}
.rastreo-info .accordion-button{
    color: #161921;
    font-size: 18px;
    font-weight: 600;
}
.rastreo-info .titulo-estados{
    font-size: 17px;
    font-weight: 600;
}
.rastreo-info .card{
    box-shadow: 0px 14px 70px 0px #0000001A;
    border: none;
}
.estado-fecha{
    color: #67686C;
    font-size: 15px;
    font-weight: 500;
    font-family: 'Open Sans', 'Helvetica Neue', sans-serif;
}
.table-remitente td{
    font-size: 14px;
    font-weight: 400;
    color: #000000;
}

.table-remitente b{
    font-size: 14px;
    font-weight: 700;
    color: #161921;
}