.politicsPage{
    font-family: 'Raleway', sans-serif;
}
.politicsPage .slider{
    background-image: url('../../../public/assets/img/fondo1.png');
    background-size: cover;
    width: 100%;
    padding: 80px 10%;
}
.politicsPage .slider h1{
    font-size: 48px;
    font-weight: 700;
    line-height: 74px;
}
.politicsPage .slider span{
    font-size: 18px;
    font-weight: 600;
    line-height: 27px;
}
.politicas-contenido{
    padding: 80px 0px 50px;
}
.politicas-contenido h3{
    font-weight: 600;
}
.politicas-contenido span p{
    font-weight: 500;
}
.politicas-contenido  a{
    color: #1A1449 !important;
    text-decoration: none;
    font-weight: 600;
}

.politicas-contenido ol {
    list-style: auto;
}
.politicas-contenido li::marker{
    font-weight: 600;
}
.politicas-contenido ul {
    list-style: disc;
}

.politicas-contenido h3,h5{
    font-weight: 600 !important;
}