
.topbar{
    background-color: #212121;
    font-size: 12px;
    color: #FFFFFF;
}
.sociales {
    display: flex;
    gap: 12px;
}
.politicas>li>a {
    text-decoration: none;
    color: #FFFFFF;
}

.politicas .border-start {
    border-color: #adb5bd8a !important;
}


.sociales a{
    color: #FFFFFF;
}

.navbar .contenedor{
    width: 100%;
}
.fa-globe{
    font-size: 1.3rem;
}
.layer .links{
    margin-right: 40px;
}
.layer .links a{
    color: white;
}

@media (max-width: 992px) {
    .topbar{
        display: none;
    }
    
  }
