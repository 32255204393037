.nav-perfil {
    background-color: #ffffff;
   
    padding: 25px;

    min-width: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: column;
      gap: 2px;
      li {
        cursor: pointer;
        padding: 15px;
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 15px;
        &:hover {
          background-color: #f1f1f1;
        }
        
      }
    }
  }
  .nav-perfil >ul >.selected{
    background-color: #f1f1f1;
  }
  .perfil-detail{
    display: flex;
    flex-direction: column;
    padding: 10px;
    gap: 10px;
  }
  
  .main-perfil {
    max-width: 1000px;
    padding: 20px;
    flex-grow: 1;
    border-left: solid 1px #212121;
  }
  .img-perfil{
    height: 80px;
    margin-left: 20%;
  }
  .data-perfil{
    margin-top: 12px;
    
    span {
      font-size: 14px;
    };
    small{
      font-size: 13px;
    };
    a{
      font-size: 13px;
      text-align: center;
      font-weight: 600;
    }
  }

  @media only screen and (max-width: 992px) {
    .perfil-admin{
      flex-direction: column !important;
    }
    .main-perfil {
      border-left: none;
    }
    .nav-perfil{
      padding: 0px ;
    }
    .nav-perfil ul{
      flex-direction: row;
      width: 100%;
      justify-content: space-around;
    }

    .nav-perfil ul>li>span{
      font-size: 12px;
      
    }
    .data-perfil a{
      border-bottom: none !important;
    }
  }

  @media only screen and (max-width: 720px) {
    .nav-perfil ul>li>span{
      display: none;
    }
    

  }
  @media only screen and (max-width: 540px) {
    .perfil-detail{
      flex-direction: row-reverse;
      width: 100%;
      padding: 0px 40px;
    }
    .img-perfil{
      width: 50%;
    }

  }