.background-login {
  height: 100vh;
  background-image: url("../../../../public/assets/img/imagen-login.jpg");

  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100vw;
}
.login-card-container {
  height: 70dvh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.login-card {
  display: flex;
  min-width: 286px;
  width: 40%;
  max-width: 500px;
  padding: 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  border-radius: 32px;
  background: #fff;
  box-shadow: 0px 12px 23px 0px rgba(62, 73, 84, 0.04);
  color: #1a1449;
  margin: 20px;
}
.btn-login {
  width: 50%;
  height: 48px;
  border-radius: 8px;
  background-color: #1a1449 !important;
  color: #fff !important;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  border: none;
  cursor: pointer;
}
.form-group-password {
  position: relative;
}
.password-icon {
  position: absolute;
  right: 10px;
  top: 43px;
  cursor: pointer;
  z-index: 10;
}
