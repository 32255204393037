.slider{
    width: 100%;
    height: 100%;
    position: relative;
   
    color: #ffffff;
    font-family: 'Raleway', sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.slider video{
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: brightness(0.5);
}
.contenido-slider{
    position: relative;
    padding: 120px 10%;
    text-align: center;
}
.slider h1{
    font-weight: 900;
    font-size: 65px;
    
}
.sup-h1{
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase; 
}
.container-cards{
    margin-top: 200px;
    display: flex;
    gap: 20px;
}
.item-slider{
    display: flex;
    background-color: #1A1449;
    text-align: left;
    padding: 20px;
    flex-flow: column;
}
.item-slider span{
    font-size: 16px;
}
.item-slider h3{
    font-size: 30px;
    font-weight: 600;
}
.item-slider p{
    margin-top: 20px;
    width: 75%;
}
.item-slider button{
   background-color: #FFD200;
   font-size: 12px;
   color: #000000;
   font-weight: 600;
   padding: 10px 20px;
   border: solid 1px #FFD200;
   border: none;
   font-weight: 600;
   border-radius: 20px;
   margin-top: auto;
   width: 110px;
}
.item-slider button:hover{
    background-color: #fad739;
}
.nosotros .container{
    display: flex;
    flex-direction: row;
    padding: 50px 0px;
    color: #1A1449;
}
.nosotros-contenido{
    padding: 50px 100px;
}
.sup-nosotros{
    font-size: 16px;
    font-weight: 600;
}
.nosotros-contenido span span{
    font-size: 24px;
    font-weight: 700;
}
.nosotros-contenido span>p{
    color: #161921;
    font-size: 16px;
    font-weight: 600;
}
.nosotros-btn{
    display: flex;
    gap: 20px;
    margin-top: 40px;
}
.nosotros-contenido h2{
    font-size: 50px;
    font-weight: 700;
}
.nosotros-contenido button{
    background-color: #FFD200;
    font-size: 13px;
    color: #000000;
    padding: 13px;
    border: solid 1px #FFD200;
    border: none;
    border-radius: 20px;
    margin-top: auto;
    width: 110px;
    font-weight: 600;
    letter-spacing: 1px;
}
.btn-escribenos{
    background-color: #1A1449 !important;
    color: #ffffff !important;
    border: solid 1px #1A1449 !important;
}

.btn-escribenos:hover{
    color: #1A1449 !important;
    background-color: #ffffff !important;
}

.btn-leer-mas:hover{
    background-color: #fad739;
}
.homePage .contacto{
    background-image: url('../../../public/assets/img/car1.jpg');
    background-size: cover;
    color: #ffffff;
}
.contacto .container{
    padding: 80px 100px;
}
.contenedor-contacto{
    display: flex;
    align-items: center;
}
.contenedor-contacto h2{
    font-size: 42px;
}
.contenedor-contacto small{
    font-size: 16px
}
.contenedor-contacto button{
    color: #1A1449;
    font-size: 16px;
    font-weight: 600;
    background-color: #FFD200;
    border: none;
    border-radius: 14px;
    padding: 12px 22px
}
.contenedor-contacto button:hover{
    background-color: #c7a302;
}
.oferta .container{
    padding: 80px 100px;
    text-align: center;
    color: #1A1449;
}
.oferta-titulo {
    padding-bottom: 35px;
}
.oferta-titulo span{
    font-size: 16px;
    font-weight: 600;
}
.oferta-titulo h2{
    font-size: 40px;
    font-weight: 700;
}

.oferta .card{
    padding: 40px;
    border: none;
}
.oferta .accordion-item{
    border: none;
    border-bottom: solid 1px #dee2e6;
}
.oferta .accordion-button{
    color: #000000;
    font-size: 19px;
    font-weight: 600;
    padding: 25px;
}
.oferta .accordion-body{
    text-align: left;
}
.oferta .accordion-button:not(.collapsed) {
    background-color: #16192113;
}
  .oferta .accordion-button:focus{
    outline: none;
    box-shadow: none;
  }

  .testimonios .container{
    padding: 80px 100px;
    text-align: center;
    color: #1A1449;
}
.testimonios-titulo {
    padding-bottom: 35px;
}
.testimonios-titulo span{
    font-size: 16px;
    font-weight: 600;
}
.testimonios-titulo h2{
    font-size: 40px;
    font-weight: 700;
}
@media only screen and (max-width: 1024px) {
    .slider .sup-h1{
        font-size: 18px;
    }
    .slider h1{
        font-size: 41px;
        max-width: 100%;
    }
    .container-cards{
        flex-direction: column;
    }
    .nosotros .container{
        flex-direction: column;
    }
    .nosotros-contenido{
        order: 2;
        padding: 20px;
    }
    .nosotros-btn{
        justify-content: center;
    }
    .nosotros-imagen{
        order: 1;
    }
    .nosotros-imagen img{
        width: 100%;
        height: 250px;
        object-fit: cover;
        object-position: 20% 30%;
    }
    .nosotros-contenido h2{
        font-size: 35px;
    }
    .contacto .container{
        padding: 50px 20px;
    }
    .contenedor-contacto{
        text-align: center;
        gap: 30px;
    }
    .oferta .container{
        padding: 80px 20px;
    }
    .oferta .card{
        padding: 0px;
    }
    .oferta .accordion-button{
        font-size: 16px;
    }
    .oferta .accordion-body{
        font-size: 14px;
    }
    .testimonios .container{
        padding: 80px 20px;
    }
}



