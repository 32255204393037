.card-swiper{
    border: none!important;
    padding: 20px;
    padding-top: 50px;
    min-height: 280px!important;
}
.card-swiper>p{
    text-align: left;
    font-size: 18px;
    line-height: 24px;
    color: #74787C;
}
.footer-card{
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: auto;
}
.footer-client{
    display: flex;
    flex-direction: column;
    text-align: left;
    color: #1A1449;
}
.footer-client span{
    font-size: 18px;
    font-weight: 600;
}
.footer-client small{
    font-size: 14px;
}
.swiper{
    padding: 20px;
}
.section-slider-nav{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    color: #ffffff;
    border: none;
}
.-prev{
    background-color: #000000;
}
.-prev:disabled{
    background-color: #00000073;
}

.-next{
    background-color: #3FA8DF;
}
.-next:disabled{
    background-color: #3FA8DF73;
}
.swiper-btn{
    display: flex;
    gap: 10px;
    justify-content: center;
}