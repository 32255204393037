@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

html {
  width: 100%;
  height: 100%;
}

body {
  /*background: linear-gradient(-45deg, #ee7752, #e73c7e, #5966ba, #0639df);*/
  background-size: 300% 300%;
  animation: gradient 15s ease infinite;
  height: 100vh;
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

input {
  color: white !important;
}

::-webkit-scrollbar {
  width: 0.5rem;
}

::-webkit-scrollbar-thumb {
  background: #92929e;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background: #495057;
}
nav ::-webkit-scrollbar{
  display: none;
}

.home{
  display: flex;
  height: 90vh;
  align-items: start;
  justify-content: start;
  background-color: #F4F6F8;
  width: 100%;
  transition: 750ms;
  margin-left: none;
  gap: 10px
}

.reports,
.carriers,
.orders,
.status-order,
.users,
.products{
  display: flex;
  height: 90vh;
  align-items: start;
  justify-content: center;
  
  background-color: #F4F6F8;
  width: 100%;
  transition: 750ms;
  margin-left: none;
}

.home.active,
.reports.active,
.orders.active,
.users.active,
.carriers.active,
.status-order.active,
.products.active{
  width: calc(100vw - 250px);
  margin-left: auto;
  transition: 350ms;
}

@media only screen and (max-width: 992px) {
  .home.active,
.reports.active,
.orders.active,
.users.active,
.carriers.active,
.status-order.active,
.products.active{
  width: initial;
  margin-left: auto;
}
}

.rdt_TableBody{
  min-height: 300px!important;
}

.h-divider{
  height: 1px;
}
.border-cancel{
  border: 1px solid #E2362F
  
}

input{
  color: #6B6B6B!important;
  font-size: 14px;
  font-weight: 400;
}
.custom-check{
  cursor: pointer;
  background-color: #F0F0F0;
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
  width: 20px;
  height: 20px;
  appearance: none;
  border-radius: 50%;
  border: 1px solid none;
  transition: background-color 0.3s ease-in-out;
}

.custom-check:checked {
  background-color: #1A1449;
}
.custom-check:focus {
  border-color: #1A1449;
} 