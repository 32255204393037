.section-button .btn-cancelar{
    padding: 10px 20px;
    border-radius: 10px;
    font-size: 15px;
    background-color: transparent;
    border: none;
    text-transform: uppercase;
    color: #1A1449;
    font-weight: 600;
}

.btn-eliminar{
    padding: 7px 20px;
    border-radius: 10px;
    font-size: 15px;
    background-color: #DD0606;
    border: none;
    text-transform: uppercase;
    color: #ffffff;
    font-weight: 600;
    max-width: 190px;
}
.btn-next:hover{
    background-color:#ffd621;
}
.btn-cancelar:hover{
    border: solid 1px #000000;
}

.btn-eliminar:hover{
    background-color: #fa0707;
}
.invalid-input{
    border-color: red!important;
}
.password-admin .password-icon{
     right: 25px;
     top: 42px;
}