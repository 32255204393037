.servicesPage .servicios{
    padding-top: 100px;
    padding-bottom: 100px;
    display: flex;
    gap: 30px;
   
}
.servicesPage .slider{
    background-image: url('/public/assets/img/fondo1.png');
    height: 350px;
    justify-content: center;
}
.servicesPage .servicios-lista{
    max-width: 250px;
}
.servicesPage .servicios-lista button {
    color: #1A1449!important;
    border-radius: 22px;
    font-size: 17px;
    display: flex;
    width: 100%;
    justify-content: space-between;
}
.servicesPage .servicios-lista .nav{
    margin-top: 20px;
}
.servicesPage .servicios-lista ul{
    gap: 10px;
}
.servicesPage .servicios-lista li{
    width: 250px;
}
.servicesPage .servicios-lista li:before{
    content: '';
    display: none;
}
.servicesPage .servicios-lista .active{
    background-color: #FFD200 !important;
    color: #1A1449!important;
}
.servicios-lista span{
    color: #1A1449;
    font-size: 22px;
    font-weight: 550;
    line-height: 26px;
}
.brochure{
    display: flex;
    flex-direction: column;
    
}
.body-brochure{
    display: flex;
    flex-direction: column;
    background-color: #FFD200;
    padding: 30px 35px;
    border-radius: 10px 10px 0px 0px;
}
.download{
    display: flex;
    background-color: #1A1449;
    cursor: pointer;
    padding: 10px;
    justify-content: center;
    border-radius: 0px 0px 10px 10px;
}
.download span{
    color: #ffffff;
    font-size: 17px;
    font-weight: 400;
}
.download span i{
    color: #ffffff;
    font-size: 12px;

}
.servicios-contenido {
    max-width: 808px;
}
.servicios-contenido .tab-pane h3{
    color: #1A1449;
    font-size: 44px;
}
.servicios-contenido .tab-pane p{
    font-size: 18px;
}

.servicios-contenido .tab-pane li:before{
    display: none;
}

.servicios-contenido .tab-pane img{
    margin-top: 20px;
}
.servicios-contenido .soluciones{
    margin-top: 120px;
}
.card-soluciones{
    display: flex;
    gap: 80px;
    flex-wrap: wrap;
    padding-top: 40px;
}
.item-soluciones{
    width: 200px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.item-soluciones span {
    font-size: 22px;
    line-height: 26px;
}

@media only screen and (max-width: 1024px) {
    .servicesPage .servicios{
        flex-direction: column;
    }
    .servicesPage .servicios .servicios-contenido{
        order: 1;
    }
    .servicesPage .servicios .servicios-lista{
        order: 2;
    }
    .servicios-contenido h3{
        font-size: 35px !important;
    }
    .item-soluciones{
        width: 100%;
    }
    .servicesPage .servicios-lista button {
        width: 100%;
    }
    .servicesPage .servicios-lista{
        max-width: 100%!important;
    }
    .servicesPage .servicios-lista li{
        width: 100%;
    }
}