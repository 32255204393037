.micuentaPage{
    font-family: 'Raleway', sans-serif !important;
}
.micuentaPage .slider{
    background-image: url('../../../public/assets/img/fondo1.png');
    background-size: cover;
    width: 100%;
    padding: 80px 10%;
}
.container-cuenta h3{
    font-size: 28px;
    font-weight: 700;
    text-transform: uppercase;
}
.container-cuenta .form-control{
    border-radius: 22px;
    padding: 20px;
    border: solid 0.5px #000000;
    color: #161921!important;
    font-weight: 600!important;
}
.container-cuenta button{
    background-color: #FFD200;
    padding: 16px 20px;
    font-size: 16px;
    font-weight: 700;
    margin-top: 30px;
    border-radius: 22px;
}
.container-cuenta form button:hover{
    background-color: #FFD200;
    color: #ffffff;
}
.container-cuenta a{
    padding-top: 15px;
    font-size: 14px;
    color: #161921!important;
    font-weight: 600;
    display: flex;
    gap: 5px;
    text-decoration: none;
}
.container-cuenta a:hover{
    color: #3f4658!important;
    text-decoration: underline;
}
.btn-register{
    border: none;
    color: white;
    background-color: #161921!important;
    margin-top: 5px!important;
}

.container-cuenta .btn{
    background-color: #FFD200;
    padding: 16px 20px;
    font-size: 16px;
    font-weight: 700;
    margin-top: 5px!important;
    border-radius: 22px;
}
.container-cuenta small{
    margin-top: 8px;
}
.error-form{
    border-color: #dc3545!important;
}
.status-note{
    max-width: 220px;
    overflow: hidden;
    text-overflow: ellipsis;
}

@media only screen and (max-width: 992px) {
    .micuentaPage>.container{
        max-width: 100% !important;
        padding: 50px;
    }
}
@media only screen and (max-width: 720px) {
    .micuentaPage>.container{
        max-width: 100% !important;
        padding: 10px;
    }
}
@media only screen and (min-width: 1400px) {
    .status-note{
        max-width: 420px;

    }
}