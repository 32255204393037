.navbar{
    background-color: #F4F6F8;
    display: flex;
    justify-content: start;
    align-items: center;
    width: 100%;
    
    margin-left: auto;
}

.menu{
    width: 100%;
    transition: 750ms;
}
.menu.active{
    width: calc(100vw - 250px);
    margin-left: auto;
    transition: 350ms;
}


.menu-bars{
    margin-left: 2rem;
    font-size: 2rem;
    background: none;
}
.menu-bars i{
    color: #036666!important;
}
.nav-menu-title{
    display: flex;
    flex-direction: column;
    gap: 20px;
    color: white;
    padding: 8px 0px 8px 16px;
    
}
.nav-menu{
    background-color: #1A1449;
    width: 240px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    position: fixed;
    align-items: flex-start;
    top: 0;
    left: -100%;
    transition: 850ms;
    border-radius: 0px 22px 0px 0px;
    z-index: 1;
}
.nav-menu.active{
    left: 0;
    transition: 350ms;
}
.nav-text{
    display: flex;
    justify-content: start;
    align-items: center;
    
    list-style-type: none;

}
.nav-text a{
    text-decoration: none;
    border: none;
    color: #f5f5f5;
    width: 95%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 10px;
    border-radius: 4px;
}
.nav-text a:hover{
    background-color: #1a83ff;
}
.nav-menu-items{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.nav-menu-items .nav-link:hover{
    color: white;
}
.navbar-toggle{
    background-color: #1A1449;
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: start;
    align-items: center;
}
.navbar-toggle a{
    border: none;
}
.nav-menu-items span{
    margin-left: 16px;
}

.nav-text {
    position: relative;
  }
  
  .nav-text-submenu{
    margin-left: 20px;
    color: #ffffff;
    width: 95%;
  }
  
  .submenu {
    display: none;
    position: absolute;
    top: 60px; /* Ajusta la posición vertical según sea necesario */
    left: 0;
    background-color: #1A1449;
    border-radius: 0 0 4px 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1;
  }
  .sub-menu.nav-link:focus{
    color: white!important
  }
  .nav-text-submenu .nav-link:focus{
    color: white!important
  }

  .container-submenu{
    width: 92%;
  }

  .sub-menu.nav-link span .drop{
    position: absolute;
    right: 0;
    margin: 25px;
    margin-top: 5px;
    transition: 0.3s ease;
  }
.rotate{
    transform: rotate(90deg);
}
  