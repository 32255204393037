.container-button{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}
.btn-dashboard-primary{
    width: 100%;
    height: 100%;
    border-radius: 10px;
    font-size: 15px;
    background-color: #FFD200;
    border: none;
    text-transform: uppercase;
    color: #1A1449;
    font-weight: 600;
}