.container-search{
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
    width: 100%;
    border: solid 1px #DFE3E8;
    padding: 8px 25px;
    border-radius: 10px;
}
.input-search{
    font-size: 16px;
    width: 100%;
    border: none;
    align-items: center;
    color: #6B6B6B!important;
}
.input-search:focus {
    outline: none;
  }