.contenedor-modal{
    font-family: 'Manrope';
    height: initial;
}
.modal-icon span{
    background-color: #FF8B8B;
    color: #FFFFFF;
    padding: 8px;
    display: flex;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    justify-content: center;
    align-items: center;
    font-size: 24px;
}
.modal-icon span.icon-check{
    background-color: #6FCF97 !important;
}
.modal-icon span.icon-alert{
    background-color: #FFD200CC !important;
}
.modal-icon.icon-save{
    text-align: center;
    display:flex ;
    justify-content: center;
}
.modal-question{
    color: #151515;
    font-weight: 500;
    font-size: 18px;
}
.modal-question small{
    color: #6B6B6B;
    font-weight: 400;
    font-size: 14px;
}
.modal-cabecera{
    display: flex;
    gap: 20px;
}
.modal-cabecera.type-save{
    flex-direction: column;
    justify-content: center;
    text-align: center;
}
.modal-question{
    display: flex;
    flex-direction: column;
    gap: 5px;
}
.contenedor-modal .modal-footer{
    display: flex;
    justify-content: space-between !important;
    border: none;
}
.modal-footer.footer-save{
    justify-content: center !important;
}

.btn-cancel{
    width: 45%;
    border: solid 1px #DFE3E8;
    padding: 10px;
    border-radius: 10px;
    text-align: center;
    color: #1A1449;
    font-size: 15px;
    font-weight: 600;
    cursor: pointer;
}
.btn-acept{
    border: solid 1px #FFD200;
    background-color: #FFD200;
    padding: 10px;
    border-radius: 10px;
    width: 45%;
    text-align: center;
    color: #FFFFFF;
    font-size: 15px;
    font-weight: 600;
    cursor: pointer;
}
.modal-form label{
    font-size: 12px;
    font-weight: 500;
}
.tipo-cambios-modal{
    .modal-content{
        max-width: 600px;
    }
    .header-tipo-cambio{
        background-color: rgb(240, 245, 250);
        padding: 25px;
        font-size: 18px;
        font-weight: 600;
    }
}


.tipo-cambios-body{
    padding: 15px;
    max-height: 600px;
    overflow: auto;
    ul{
        display: flex;
        flex-direction: column;
        gap: 5px;
        li{
            display: flex;
            gap: 10px;
        }
    };
    i{
        width: 25%;
        height: 25px;
        display: flex;
        justify-content: center;
        svg{
            height: 15px;
        }
    }

}
.limites{
    margin-left: auto;
    margin-right: 10px;
}

.image-selector{
    display: flex;
    justify-content: space-around;
    gap: 20px;
    .left-square{
        width: 100px;
        height: 120px;
        border: solid 1px #1a14495b;
        border-radius: 5px;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 32px;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
        label{
            cursor: pointer;
        }
    }
    .right-square{
        width: 100px;
        height: 120px;
        border-radius: 50%;
        background-color: #a7a4a4;
        overflow: hidden;
        img{
            object-fit: cover;
            width: 100px;
        height: 120px;
        }
    }
}