.tracking-component{
   border-radius: 6px;
    width: 350px;
    height: auto;
    border: solid 1px #D3D3D3;
    box-shadow: 0px 14px 70px 0px #0000001A;
    background-color: white;
    z-index: 1;
    overflow: auto;
   
    position: absolute;
    top: 140px;
    right: 10px;

}
.tracking-component i{
    position: relative;
    right: 10px;
    bottom: -10px;
    font-size: 14px;
    cursor: pointer;
}
.container-track{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    padding: 30px;
    gap: 20px;
}
.body-track{
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    text-align: left;
    justify-content: left;
    align-items: start;
    padding: 5px;
}
.footer-track{
    display: flex;
    width: 100%;
}
.body-track span{
    font-size: 14px;
    font-weight: 500;
    color: #000000;
}
.input-track{
    width: 100%;
    border-bottom: solid 1px #000000;
}
.input-track input{
    width: 100%; 
    border: none;
    outline: none;
    color: #000000!important;
}
.footer-track button{
    background-color: #000000;
    color: #FFFFFF;
    width: 100%;
    border-radius: 22px;
    border: none;
    font-size: 13px;
    font-weight: 600;
    padding: 10px;
}

.group-status{
    margin-top: 10px;
}
.status{
    display: flex;
    gap: 20px;
    align-items: center;
}
.status i{
    position: relative;
    color: #1A1449;
    font-size: 24px;
}
.status .linea{
    left: 12px;
    top: 18px;
    position: absolute;
    width: 3px;
    height: 40px;
    background-color: #1A1449;
    transform: translateX(-50%);
}
.status .linea-sup{
    left: 12px;
    top: -30px;
    position: absolute;
    width: 3px;
    height: 30px;
    background-color: #1A1449;
    transform: translateX(-50%);
}

.detail-status{
    display: flex;
    flex-direction: column;
    margin-top: 5px;
}
.detail-status small{
    font-weight: 400;
    font-size: 14px;
    color: #67686C
}
.status .detail-status span{
    color: #4d4d4d;
    font-size: 15px;
    font-weight: 600;
}
.status:last-child .detail-status span {
    font-size: 20px;
    color: #1A1449;
}

@media only screen and (max-width: 1024px) {
    .tracking-component{
        position: initial;
        margin-top: 10px;
    }
}


@media only screen and (max-width: 992px) {
    .container-tracking{
        padding: 0px;
    }
    .detail-status span{
        color: #161921;
        font-size: 18px;
        font-weight: 600;
    }
    .rastreo span{
        font-size: 28px;
    }
}

@media only screen and (max-width: 420px) {
    
    .rastreo span{
        font-size: 26px !important;
    }
}