.lds-spinner {
    color: official;
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-spinner div {
    transform-origin: 40px 40px;
    animation: lds-spinner 1.2s linear infinite;
  }
  .lds-spinner div:after {
    content: " ";
    display: block;
    position: absolute;
    top: 3px;
    left: 37px;
    width: 6px;
    height: 18px;
    border-radius: 20%;
    background: #fff;
  }
  .lds-spinner div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
  }
  .lds-spinner div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s;
  }
  .lds-spinner div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s;
  }
  .lds-spinner div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s;
  }
  .lds-spinner div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s;
  }
  .lds-spinner div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s;
  }
  .lds-spinner div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
  }
  .lds-spinner div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s;
  }
  .lds-spinner div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s;
  }
  .lds-spinner div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
  }
  .lds-spinner div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s;
  }
  .lds-spinner div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
  }
  @keyframes lds-spinner {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  
  .container-spinner{
    background-color: rgba(0, 0, 0, 0.308);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
  }

  .charge {
    animation: chargeLoad 2.5s infinite linear both;
  }
  
  .charge-dot {
    animation: chargeLoad-dot 2s infinite ease-in-out both;
  }
  .charge-dot:before {
    content: "";
    display: block;
    width: 25%;
    height: 25%;
    background-color: #1A1449;
    border-radius: 100%;
    animation: chargeLoad-dot-before 2s infinite ease-in-out both;
  }
  .charge-dot:nth-child(1) {
    animation-delay: -1.1s;
  }
  .charge-dot:nth-child(2) {
    animation-delay: -1s;
  }
  .charge-dot:nth-child(3) {
    animation-delay: -0.9s;
  }
  .charge-dot:nth-child(4) {
    animation-delay: -0.8s;
  }
  .charge-dot:nth-child(5) {
    animation-delay: -0.7s;
  }
  .charge-dot:nth-child(6) {
    animation-delay: -0.6s;
  }
  .charge-dot:nth-child(1):before {
    animation-delay: -1.1s;
  }
  .charge-dot:nth-child(2):before {
    animation-delay: -1s;
  }
  .charge-dot:nth-child(3):before {
    animation-delay: -0.9s;
  }
  .charge-dot:nth-child(4):before {
    animation-delay: -0.8s;
  }
  .charge-dot:nth-child(5):before {
    animation-delay: -0.7s;
  }
  .charge-dot:nth-child(6):before {
    animation-delay: -0.6s;
  }
  
  @keyframes chargeLoad {
    100% {
      transform: rotate(360deg);
    }
  }
  
  @keyframes chargeLoad-dot {
    80%,
    100% {
      transform: rotate(360deg);
    }
  }
  
  @keyframes chargeLoad-dot-before {
    50% {
      transform: scale(0.4);
    }
    100%,
    0% {
      transform: scale(1);
    }
  }