.aboutPage{
    font-family: 'Raleway', sans-serif;
}
.aboutPage .slider{
    background-image: url('../../../public/assets/img/fondo1.png');
    background-size: cover;
    width: 100%;
    padding: 80px 10%;
}
.aboutPage .slider h1{
    font-size: 40px;
    font-weight: 700;
    line-height: 74px;
}
.aboutPage .slider span{
    font-size: 16px;
    font-weight: 600;
    line-height: 27px;
}
.nosotros-contenido{
    padding: 100px 0px 50px;
}
.nosotros-contenido h3{
    font-weight: 600;
}
.nosotros-contenido span p{
    font-weight: 500;
}
.experiencia .container{
    display: flex;
    flex-direction: column;
    padding: 50px 100px;
    color: #1A1449;
    background-image: url('../../../public/assets/img/image-3.png');
    background-size: cover;
    width: 100%;
    text-align: center;
}
.container-tittle{
    padding-top: 50px;
}
.container-tittle span{
    font-size: 16px;
    font-weight: 600;
    line-height: 18px;
}
.container-tittle h2{
    font-size: 50px;
    font-weight: 700;
    line-height: 39px;
}
.experiencia-estadisticas{
    display: flex;
    gap: 30px;
    text-align: center;
    justify-content: center;
}
.stat-item{
    display: flex;
    flex-direction: column;
}
.stat-item span{
    font-size: 100px;
    font-weight: 700;
}
.stat-item small{
    font-size: 16px;
}

.video-nosotros .container{
    display: flex;
    flex-direction: column;
    padding: 50px 100px;
    background-image: url('../../../public/assets/img/image-4.png');
    background-size: cover;
    width: 100%;
    height: 535px;
}

.valores .container{
    display: flex;
    flex-direction: column;
    padding: 50px 100px;
    color: #1A1449;
    text-align: center;
}
.container-valores{
    padding-top: 30px;
    padding-bottom: 100px;
}
.container-valores .nav-pills{
    display: flex;
    gap: 20px;
    justify-content: center;
}
.container-valores .nav-item{
    border: solid 1px #DEDBD5;
    border-radius: 22px;
    
    font-size: 14px;
    font-weight: 600;
    line-height: 17.5px;
}
.container-valores .nav-item button{
    padding: 20px 25px;
    color: #1A1449!important;
    border-radius: 22px;
    min-width: 200px;
}
.container-valores .nav-item .active{
    border-radius: 22px;
    background-color: #FFD200 !important;
    color: #1A1449!important;
}

@media only screen and (max-width: 1024px) {
    .container-tittle h2{
        font-size: 35px;
    }
    .experiencia-estadisticas{
        flex-direction: column;
    }
    .nosotros-contenido{
        padding: 30px 10px;
    }
    .valores .container{
        padding: 50px 0px;
    }
    .container-valores .nav-item {
        width: 90%;
    }
    .container-valores .nav-item button {
        width: 100%;
    }
    #pills-tabContent{
        padding: 10px;
    }
}

.width-vision{
    width: 98%;
}