.contactPage{
    font-family: 'Raleway', sans-serif !important;
}
.contactPage .slider{
    background-image: url('../../../public/assets/img/fondo1.png');
    background-size: cover;
    width: 100%;
    padding: 80px 10%;
}
.contacto-container{
    padding: 80px;
}
.contact-group{
    display: flex;
    flex-direction: row;
    gap: 20px;
}
.contact-icon{
    display: flex;
    background-color: #FFD200;
    border-radius: 50%;
    padding: 10px 12px;
    align-items: center;
    align-self: start;
}
.contact-info{
    display: flex;
    flex-direction: column;
    color: #1A1449;
}
.contact-info span{
    font-size: 24px;
    font-weight: 700;
}
.contact-info small{
    font-size: 16px;
}
.contactanos{
    color: #161921;
}
.contactanos>.contactanos-countries .tab-pane>span {
    font-size: 16px;
    font-weight: 600;
}
.contactanos>h2 {
    font-size: 50px;
    font-weight: 700;
}
.contactanos>p {
    font-size: 16px;
}
.contactanos-countries{
    margin-top: 30px;
}
.contactanos-countries>span{
    font-size: 22px;
    font-weight: 600;
}
.formulario-info{
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.info-item{
    display: flex;
    gap: 20px;
}
.info-item>span {
        display: flex;
        background-color: #FFD200;
        border-radius: 50%;
        padding: 10px 12px;
        align-items: center;
        align-self: start;
}
.info-item>p {
    font-size: 16px;
    font-weight: 600;
}

.form-contact h3{
    font-size: 24px;
    font-weight: 700;
}
.form-contact form{
    display: flex;
    padding: 10px;
    
}
.form-contact form input{
    border-radius: 22px;
    padding: 20px;
    border: solid .5px #000000;
    color: #161921!important;
    font-weight: 600!important;
}
.form-contact form textarea{
    border-radius: 22px;
    padding: 20px;
    border: solid .5px #000000;
    color: #161921!important;
}

.form-contact form button{
    background-color: #FFD200;
    padding: 16px 20px;
    font-size: 16px;
    font-weight: 700;
    width: 186px;
    margin-top: 30px;
    border-radius: 22px;
}

.form-contact form button:hover{
    background-color: #FFD200;
    color: #ffffff;
}

#nav-tab{
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: end;
}
#nav-tab a{
    color: #1A1449 !important;
    text-decoration: none;
}
#nav-tab.active{
    display: block!important;
}
@media only screen and (max-width: 1024px) {
    .contact-group{
        display: none;
    }
    .contacto-container{
        padding: 0px;
    }
    .form-contact{
        margin-top: 20px!important;
        text-align: center;
    }
    .form-contact .row{
        width: 100%!important;
    }
}