.file-input-container {
   
    margin: auto;
    border: solid 1px #EBF0ED;
    border-radius: 10px;
  }
  
  .file-input {
    margin-bottom: 20px;
  }
  
  .file-input label {
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
  }
  
  .file-input-label {
    margin-top: 10px;
    color: #6B6B6B;
    font-size: 14px;
    font-weight: 400;
  }
  
  .upload-button {
    padding: 10px 20px;
    border-radius: 10px;
    font-size: 15px;
    background-color: #FFD200;
    border: none;
    text-transform: uppercase;
    color: #1A1449;
    font-weight: 600;
    width: 160px;
  }
  
  .file-list {
    margin-top: 0px;
    padding: 10px
  }
  
  .file-list ul {
    list-style: none;
    padding: 0;
  }
  
  .file-list li {
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
    color: #6B6B6B;
    font-size: 14px;
  }

  .file-list li a{
    color: #1A1449!important;
    margin-right: 10px;
    font-size: 14px!important;
    font-weight: 400;
    cursor: pointer;
  }
  
  