@import url('https://fonts.googleapis.com/css2?family=DM+Sans&family=Nunito:ital,wght@0,200;0,300;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&family=Roboto+Mono&family=Syne&family=Urbanist:wght@100;300&display=swap');
footer{
    background-image: url('../../../public/assets/img/background.jpg');
    background-size: cover;
    width: 100%;
    position: relative;
    font-family: 'urbanist';
}
footer h3{
    font-weight: 900;
    font-size: 30px;
}

.footer{
    display: flex;
    flex-direction: row;
    padding: 80px 0px;
    color: #ffffff;
    gap: 30px;
}
.resumen .resumen-imagenes{
    display: flex;
    gap: 2px;
    flex-wrap: wrap;
}
.resumen .resumen-imagenes img{
    width: 80px;
}
.resumen-icons{
    margin-top: 20px;
    display: flex;
    gap: 10px;
}
.resumen-icons a{
    color: #ffffff;
    padding: 8px 14px;
    border: solid 1px #ffffffad;
    border-radius: 50%;
}
.servicios{
    width: 50%;
}
.servicios ul{
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.servicios li::before{
    display: inline-block;
    content: '»';
    margin-right: 0.5rem;
}
.servicios li{
    text-indent: -0.75em;
}
.servicios li a{
    text-decoration: none;
    color: #ffffff;
    font-weight: 600;
}

.contacto-titulo{
    color: #FFD200;
    font-size: 18px;
    font-weight: 700;
}
.point{
    display: inline-block;
    content: '';
    margin-right: 0.2rem;
    background-color: #FFD200;
    width: 15px;
    height: 15px;
    border-radius: 50%;
}

.legal ul{
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.legal li::before{
    display: inline-block;
    content: '»';
    margin-right: 0.5rem;
}
.legal li{
    text-indent: -0.75em;
}
.legal li a{
    text-decoration: none;
    color: #ffffff;
    font-weight: 600;
}

.copy-right{
    border-top: solid 1px #ffffff7a;
    color: #ffffff;
    padding: 20px;
}
.social{
    display: none;
}

@media only screen and (max-width: 1024px) {
    .footer{
        flex-direction: column;
        padding: 30px 10px;
        gap: 20px;
    }
    .resumen{
        display: none;
    }
    .servicios{
        width: 100%;
        order: 1;
    }
    .legal{
        order: 2;
    }
    .contacto{
        order: 3;
        width: 100%;
        gap: 8px;
        display: flex;
        flex-direction: column;
    }
    .social{
        display: block;
        order: 4;
    }
}