.client-select{
    width: 250px;
}
.client-select input{
    color: #454F5B!important;
}
.input-group-checkbox{
    display: flex;
    margin: 0px;
    gap: 10px;
    justify-content: center;
    align-items: center;
}
#mycheck{
    cursor: pointer;
    background-color: #F0F0F0;
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
    width: 20px;
    height: 20px;
    appearance: none;
    border-radius: 50%;
    border: 1px solid none;
    transition: background-color 0.3s ease-in-out;
}

#mycheck:checked {
    background-color: #1A1449;
}
#mycheck:focus {
    border-color: #1A1449;
} 