header {
	display: flex;
	align-items: center;
	justify-content: space-between;
	height: 80px;
	padding: 0 2rem;
	background-color: var(--mainColor);
	color: var(--textColor);
	font-size: 13px;
	font-weight: 600;
}
.servicio-item{
	padding: 5px !important;
	margin: 0px !important;
}
.servicio-menu{
	border: none !important;
	border-radius: 0% !important;	
}
.login-btn{
	background-color: #000000!important;
	color: #FFFFFF !important;
	font-weight: 600 !important;
	border-radius: 22px !important;
	font-size: 13px!important;
}
.login-btn:hover{
	background-color: white !important;
  	color: black !important;
	border: solid 1px rgb(85, 85, 85) !important;
}
.track-btn{
	background-color: #FFD200!important;
	font-weight: 600 !important;
	border-radius: 22px !important;
	font-size: 13px!important;
}
.track-btn:hover{
	filter: brightness(105%);
}
.country-flag{
	font-size: 12px !important;
	display: flex !important;
	gap: 5px;
	
}
.flag-menu{
	border: none !important;
	border-radius: 0% !important;	
	min-width: 5.5rem !important;
}
.menu-btn{
	display: none!important;
	}
.nav-close-btn{
	display: none!important;
}

.collapse-servicio{
	display: none;
}

nav a {
	margin: 0 1rem;
	color: var(--textColor);
	text-decoration: none;
}

nav a:hover {
	color: var(--secondaryColor);
}

header .nav-btn {
	padding: 5px;
	cursor: pointer;
	background: transparent;
	border: none;
	outline: none;
	color: var(--textColor);
	visibility: hidden;
	opacity: 0;
	font-size: 1.8rem;
}

header div,
nav {
	display: flex;
	align-items: center;
}

@media only screen and (max-width: 1024px) {
	header .nav-btn {
		visibility: visible;
		opacity: 1;
		padding: 0;
	}
	.menu-btn{
	display: block !important;
	}
	.nav-close-btn{
		display: block!important;
	}
	.collapse-servicio{
		display: block;
		text-decoration: none!important;
	}
	.collapse-servicio::after{
		display: inline-block;
		margin-left: auto;
		vertical-align: 0.255em;
		content: "";
		border-top: 0.3em solid;
		border-right: 0.3em solid transparent;
		border-bottom: 0;
		border-left: 0.3em solid transparent;
	}
	.dd-servicio{
		display: none;
	}
	.servicio-menu{
		list-style: none;
	}
	.login-btn,.track-btn{
		padding: 10px!important;
	}
	.btn-language{
		display: none;
	}
	.item-inicio{
		padding-top: 15%;
	}

	header nav {
		position: fixed;
		top: 0;
		left: -100%;
		height: 100%;
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		
		gap: 1rem;
		background-color: white;
		transition: 1s;
		z-index: 100;
	}

	header .responsive_nav {
		transform: translateX(100vw);
		overflow-y: auto;
	}

	header .responsive_nav::-webkit-scrollbar {display: none;}

	nav .nav-close-btn {
		position: absolute;
		top: 2rem;
		right: 2rem;
	}
	.navbar-brand img{
		width: 160px;
	}

	nav a {
		font-size: 16px;
		border-bottom: solid 1px #D3D3D3;
		width: 100%;
		display: flex;
		align-items: center;
		padding: 10px;
	}
	.track-div{
		width: 100%;
		display: flex;
		flex-direction: column;
		position: relative;
	}
	.track-btn{
		margin: 0px;
	}
}

@media only screen and (max-width: 720px) {
	.item-inicio{
		padding-top: 25%;
	}

}
