.image-uploader {
    position: relative;
  }
  
  .circle {
    width: 85px;
    height: 85px;
    border-radius: 50%;
    overflow: hidden;
    position: absolute;
  }
  
  .uploaded-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
  }
  
  .placeholder {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer!important;
  }
  
  .plus-icon {
    font-size: 2rem;
    color: #fff;
  }
  .image-uploader .placeholder{
    background-color: transparent;
    background-image: url('../../../../../public/assets/img/default.png');
    background-repeat: no-repeat;
    background-size: contain;
  }
  
  .icon-plus-img{
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000000;
    position: relative;
    top: 5px;
    left: 60px;
    border: solid 2px #ffffff;
    border-radius: 50%;
    background-color: #FFD200;
    cursor: pointer!important;
  }
  @media only screen and (max-width: 1200px) {
    .circle {
      width: 70px;
      height: 70px;
    }
    .icon-plus-img{
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #000000;
      position: relative;
      top: 5px;
      left: 45px;
      border: solid 2px #ffffff;
      border-radius: 50%;
      background-color: #FFD200;
      cursor: pointer!important;
    }
  }
  @media only screen and (max-width: 992px) {
    .circle {
      width: 85px;
      height: 85px;
    }
    .icon-plus-img{
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #000000;
      position: relative;
      top: 5px;
      left: 60px;
      border: solid 2px #ffffff;
      border-radius: 50%;
      background-color: #FFD200;
      cursor: pointer!important;
    }
  }
  @media (max-width: 770px) {
    .image-uploader{
      height: 90px;
      display: flex;
      justify-content: center;
    }
    .icon-plus-img{
      left: 40px;
    }
    
  }

 