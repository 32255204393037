/* Estilos para el fondo oscuro del modal */
.modal-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); /* Fondo oscuro con opacidad */
    display: none;
    justify-content: flex-end; /* Alinea el contenido a la derecha */
    align-items: center;
    
    transition: opacity 0.3s ease-in-out;
    z-index: 999; /* Asegura que esté en la capa superior */
  }
 .active{
    display: flex;
  }
  
  /* Estilos para el contenido del modal */
  .modal-content {
    background-color: #ffffff!important;
    width: 80%; /* Ajusta según tus necesidades */
    max-width: 400px; /* Ajusta según tus necesidades */
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    position: relative;
    opacity: initial;
    /*height: 100vh;*/
    overflow: auto;
  }
  .modal-content.traslate {
    background-color: #ffffff!important;
    width: 80%; /* Ajusta según tus necesidades */
    max-width: 400px; /* Ajusta según tus necesidades */
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    position: relative;
    opacity: initial;
    height: 100vh;
    
  }

  .traslate{
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
  }
  
  /* Estilos para el botón de cerrar dentro del modal */
  .modal-content button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 16px;
    color: #333;
  }
  .modal-content .btn{
    right: 0px!important;
  }
  
  /* Aplicar estilos cuando el modal está activo */
  .modal-background.active {
    opacity: 1;
  }
  
  .modal-content.active {
    opacity: 1;
    transform: translateX(0%); /* Muestra el contenido moviéndolo a la posición original */
  }

  .modal-content form button{
    position: relative;
  }
  .error-form{
    border-color: #dc3545!important;
}

.login-modal .form-control{
    border-radius: 22px;
    padding: 20px;
    border: solid 0.5px #000000;
    color: #161921!important;
    font-weight: 600!important;
}
.login-modal form button{
    background-color: #FFD200;
    padding: 16px 20px;
    font-size: 16px;
    font-weight: 700;
    margin-top: 30px;
    border-radius: 22px;
}
.login-modal form button:hover{
    background-color: #FFD200;
    color: #ffffff;
}
.login-modal a{
    padding-top: 15px;
    font-size: 14px;
    color: #161921!important;
    font-weight: 600;
    display: flex;
    gap: 5px;
    text-decoration: none;
}

header .responsive_nav {
  transform: translateX(100%);
}