.escritorio-cards{
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    padding: 10px;
}
.item-escritorio{
    cursor: pointer;
    width: 260px;
    display: flex;
    flex-direction: column;
    padding: 50px;
    border: solid 1px #161921;
    text-align: center;
    gap: 20px;
    &:hover{
        background-color: #f7f7f7;
    }
}

.detalle-pedido{
  font-size: 12px;
  font-weight: 600;
  margin-left: 15px;
}
  
.collapsed-content {
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
}

.expanded {
  max-height: 500px; /* Altura máxima para la transición */
}
.item-escritorio i{
    font-size: 34px;
}
.address-buttons{
  width: 100%;
  display: flex;
  gap: 10px;
  align-items: end;
  justify-content: end;
  .btn-delete{
    background-color: #DD0606;
    color: #FFFFFF;
    font-weight: 600;
    font-size:15px;
    &:hover{
      background-color: #bb0a0a;
      color: #FFFFFF;
    }
  };
  .btn-update{
    background-color: #FFD200;
    color: #1A1449;
    font-weight: 600;
    font-size: 15px;
    &:hover{
      background-color: #ffdd43;
      color: #1A1449;
    }
  }
}
.list-verMas{
  font-size: 1.5rem;
  cursor: pointer;
  margin-left: .5rem;
  color: #1A1449;
}
.status-tracking{
  max-width: 160px !important;
}

@media only screen and (max-width: 992px) {
    .item-escritorio{
        width: 240px;
    }
    .status-tracking{
      max-width: 140px !important;
    }
  }
@media only screen and (max-width: 720px) {
    .item-escritorio{
        width: 48%;
    }
    .status-tracking{
      max-width: 135px !important;
    }

  }
@media only screen and (max-width: 540px) {
    .escritorio-cards{
        justify-content: center;
    }
    .item-escritorio{
        width: 220px;
    }

  }