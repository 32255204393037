input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

input[type=number] { -moz-appearance:textfield; }
.container-step small{
    font-size: 13px;
    font-weight: 600;
}
.section-step{
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 35px 0px;
}
.container-step h3{
    font-size: 18px;
    font-weight: 600;
    margin: 0px;
}
.section-step small{
    color: #454F5B;
    font-size: 13px;
    font-weight: 400;
}
.section-step .switch{
    display: flex;
    color: #454F5B;
    font-size: 13px;
    font-weight: 400;
    gap: 10px;
}
.section{
    border: solid 1px #DFE3E8;
    padding: 16.5px 12px;
    border-radius: 16px;
    color: #454F5B;
}
.section label{
    font-size: 14px;
    font-weight: 600;
}
.section input{
    color: #6B6B6B!important;
    font-size: 14px;
    font-weight: 400;
}
.section-button{
    margin-top: 1.5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.section-button .btn-next{
    padding: 10px 20px;
    border-radius: 10px;
    font-size: 15px;
    background-color: #FFD200;
    border: none;
    text-transform: uppercase;
    color: #1A1449;
    font-weight: 600;
}
.section-button a{
    text-decoration: none;
    color: #001222;
    font-size: 13px;
    font-weight: 700;
    cursor: pointer;
}

.filters{
    display: flex;
    padding: 8px 25px;
    gap: 12px;
    flex-wrap: wrap;
}
.item-filter{
    display: flex;
    padding: 8px;
    gap: 10px;
    color: #6B6B6B;
    font-size: 14px;
    font-weight: 600;
}


.mycheckbox{
    cursor: pointer;
    background-color: #F0F0F0;
    background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
    width: 22px;
    height: 22px;
    appearance: none;
    border-radius: 5px;
    border: 1px solid #DEE4DF;
    transition: background-color 0.3s ease-in-out;
}

.mycheckbox:checked {
    background-color: #1A1449;
}

.status-delete{
    border: solid 1px #E2362F;
    background-color: #FFF3F4;
    border-radius: 10px;
    font-size: 14px;
    color: #E2362F;
    font-weight: 400;
    padding: 6px 15px;
}
.status-cerrado{
    border: solid 1px #FFD200;
    background-color: #FFFFFF;
    border-radius: 10px;
    font-size: 14px;
    color: #FFD200;
    font-weight: 400;
    padding: 6px 15px;
}
.status-active{
    border: solid 1px #6FCF97;
    background-color: #F4FFFF;
    border-radius: 10px;
    font-size: 14px;
    color: #6FCF97;
    font-weight: 400;
    padding: 6px 12px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
    max-width: 180px;
    margin-right: 10px;
}
.action-menu{
   border: solid 1px #EBF0ED !important; 
   background-color: #FAFAFA!important;
   color: #6B6B6B!important;
}
.action-menu::after{
    display: none!important;

}

.list-menu li a{
    padding: 16px!important;
    font-size: 14px;
    font-weight: 400;
}
.detail-order{
    display: flex;
    flex-direction: column;
    font-size: 13px;
    font-weight: 400;
}
.detail-order b{
    font-weight: 600;
}
.section-step-order{
    display: flex;
    justify-content: space-between;    
}
.section-step-order button{
    background-color: #DD0606;
    color: #FFFFFF;
    font-size: 15px;
    font-weight: 600;
    width: 160px;
    height: 50px;
}
.section-step-order button:hover{
    border-color: #DD0606!important;
    
}
.btn-add{
    display: flex;
    justify-content: right;
    padding-top: 10px;
}
.btn-add span{
    font-size: 18px;
    font-weight: 600;
    color: #1A1449;
    cursor: pointer;
}

.drop-menu{
    display: flex;
    width: 100%;
    justify-content: center;
}

@media only screen and (max-width: 1150px) {
    .drop-menu{
        justify-content: flex-end;
    }
}

@media only screen and (max-width: 770px) {
    .form-dashboard{
        padding: 0px!important;
        width: 100%!important;
    }
}